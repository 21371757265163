<template>
  <div class="wrapper" v-if="!coding">
    <div class="advanced-header">
      <h2>高级搜索</h2>
      <span @click="show = !show">{{show ? '收起' : '展开'}}<el-icon class="el-icon--right"><ArrowDown :class="{'active' : show}"/></el-icon></span>
    </div>
    <el-collapse-transition>
      <div v-show="show" class="advanced-content">
        <div v-for="(item, index) in filters" :key="index">
           <advanced-item
           @deleteItems="deleteItems(index)"
           @removeItems="(idx) => removeItems(idx, index)"
           :groups="groups"
           :filters="filters"
           v-model:item="filters[index]"
           :index="index"
           ></advanced-item>
           <div class="public-desc" v-if="(index + 1 ) !== filters.length || groups.length !== 0">
             <span class="public-btn active">和</span>
             <span class="public-btn">或</span>
            </div>
        </div>
        <div v-for="(item, index) in groups" :key="index">
          <div class="advanced-groups" v-if="item.filters.length !== 0">
            <div class="group-name">{{item.name}}</div>
            <div class="group-wrap">
              <div v-for="(gitem, gindex) in item.filters" :key="gindex">
                <advanced-item
                  @deleteItems="deleteGroupItems(index, gindex)"
                  @removeItems="(toIndex) => groupRemoveItems(toIndex, index, gindex)"
                  @toSingleItems="toSingleItems(index, gindex)"
                  :groups="groups"
                  :filters="item.filters"
                  :item="gitem"
                  :groupIndex="index"
                  :index="gindex"
                  :isGroup="true"
                >
                </advanced-item>
                <div class="public-desc" style="position: relative;left: -10px;" v-if="(gindex + 1 ) !== item.filters.length">
                  <span class="public-btn active">和</span>
                  <span class="public-btn">或</span>
                </div>
              </div>
            </div>
          </div>
          <div class="public-desc" v-if="(index + 1 ) !== groups.length && item.filters.length">
            <span class="public-btn active">和</span>
            <span class="public-btn">或</span>
          </div>
        </div>
        <div class="search-range">
          <el-checkbox-group v-model="checkList">
            <el-checkbox @change="(e) => checkboxChange(e, index)" v-for="(item, index) in optionLists" :key="index" :label="item.value">{{item.label}}</el-checkbox>>
          </el-checkbox-group>
        </div>
        <div class="buttons">
          <el-button class="filter" type="success">搜索</el-button>
          <el-button class="reset" text ><svg-icon name="chongzhi"></svg-icon>重置</el-button>
        </div>
      </div>
    </el-collapse-transition>
  </div>
  <div v-else class="wiki-wrapper">
    <div class="wiki-content__wrap">
      <img :src="$isDark() ? require('@/assets/img/help/wiki-bg.png') : require('@/assets/img/help/wiki-bg-light.png')" alt="" />
      <p>开发中，敬请期待！</p>
    </div>
  </div>
  <backTo-top isContainer></backTo-top>
</template>

<script setup>
import { reactive, toRefs } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'
import advancedItem from './component/advanced-item'

const state = reactive({
  show: true,
  checkList: [0],
  coding: true,
  optionLists: [
    {
      label: '全部',
      value: 0
    },
    {
      label: '公开任务',
      value: 1
    },
    {
      label: '我的任务',
      value: 2
    },
    {
      label: '团队任务',
      value: 3
    }
  ],
  filters: [
    {
      key: '',
      relation: '',
      value: ''
    }
  ],
  groups: [
    {
      name: '分组一',
      filters: [
        {
          key: '',
          relation: '',
          value: 'aaa'
        },
        {
          key: '',
          relation: '',
          value: 'bbb'
        }
      ]
    },
    {
      name: '分组二',
      filters: [
        {
          key: '',
          relation: '',
          value: '333'
        },
        {
          key: '',
          relation: '',
          value: '666'
        }
      ]
    }
  ]
})

const { show, checkList, optionLists, filters, groups, coding } = toRefs(state)

const checkboxChange = (check, index) => {
  if (check) {
    checkList.value = checkList.value.filter((item) => index === 0 ? item === 0 : item !== 0)
  }
}

const removeItems = (index, itemIndex) => {
  const item = filters.value.splice(itemIndex, 1)
  addGroup(index, item)
}

const toSingleItems = (index, itemIndex) => {
  const item = groups.value[index].filters.splice(itemIndex, 1)
  filters.value = [...filters.value, ...item]
}

const groupRemoveItems = (toIndex, fromIndex, index) => {
  if (toIndex - 1 === fromIndex) return
  const item = groups.value[fromIndex].filters.splice(index, 1)
  addGroup(toIndex, item)
}

const addGroup = (toIndex, item) => {
  if (!toIndex) {
    const arr = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
    groups.value.push({
      name: `分组${arr[groups.value.length]}`,
      filters: item
    })
  } else {
    groups.value[toIndex - 1].filters = [...groups.value[toIndex - 1].filters, ...item]
  }
}

const deleteGroupItems = (index, gindex) => {
  groups.value[index].filters = groups.value[index].filters.filter((item, idx) => gindex !== idx)
}
const deleteItems = (index) => {
  if (filters.value.length === 1) return
  filters.value = filters.value.filter((item, idx) => index !== idx)
}
</script>
<style lang="scss" scoped>
.wiki-wrapper {
  padding: 0 24px;
  height: calc(100% - 60px);
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.wrapper {
  padding:0 24px;
}
.advanced-header {
  height:58px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  span {
    color: var(--color-primary);
    cursor: pointer;
    svg {
      transition: all 0.5s;
    }
    i {
      position: relative;
      top: 2px;
      & .active {
        transform: rotateZ(180deg);
      }
    }
  }
  h2 {
    font-size: 18px;
    color: var(--color-text-1);
    font-weight: 500;
  }
}
.advanced-content {
  background-color: var(--detect-config-bg);
  padding: 20px;
  .public-desc {
    color: var(--color-text-2);
    margin: 6px 0;
    display: flex;
    justify-content: center;
    .public-btn {
      display: inline-block;
      box-sizing: border-box;
      border: 1px solid var(--color-text-3);
      border-radius: 0px 2px 2px 0px;
      width: 58px;
      height: 26px;
      line-height: 24px;
      font-size: 12px;
      cursor: pointer;
      text-align: center;
      color: var(--color-text-2);
      &.active {
        background-color: #00ab7a;
        color: var(--color-text-1);
      }

      &:first-child {
        border-radius: 2px 0px 0px 2px;
        border-right: none;
      }

      &:last-child {
        border-radius: 0 2px 2px 0;
        border-left: none;
      }
    }
  }
  .advanced-groups {
    background: var(--color-bg-3);
    border: 1px solid #5a5e6d4d;
    border-radius: 2px;
    display: flex;
    margin: 0 24px;
    .group-name {
      width: 16px;
      padding: 0 2px;
      background: #009168;
      text-align:center;
      border-radius: 2px 0px 0px 2px;
      font-size: 12px;
      color: var(--color-text-1);
      display: flex;
      align-items: center;
    }
    .group-wrap {
      flex: 1;
      padding: 8px 0;
    }
  }
  .search-range {
    margin-top: 20px;
  }
  .buttons {
    display: flex;
    flex-direction: row-reverse;
    .reset {
      border: none;
      color: var(--color-primary);
    }
    .filter {
      border: none;
      background: #009e71;
      padding:0 20px;
      border-radius: 2px;
    }
  }
}
</style>
